/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

button {
  border-top-style: inherit !important;
  border-right-style: inherit !important;
  border-bottom-style: inherit !important;
  border-left-style: inherit !important;
}

ion-modal {
  --border-radius: 8px !important;
}

ion-modal.modal-full-width {
  --width: 70%; 
  --max-width: 70%; 
  --border-radius: 0; 
  --height: auto !important;
  @media only screen and (max-width: 768px) {
    --width: 95% !important;
  }
  .ion-page {
    position: relative !important;
    display: block !important;
    contain: content !important;
    .inner-content {
      max-height: 80vh !important;
      overflow: auto;
      padding: 10px !important;
    }
  }
}

ion-modal.auto-height {
  border-radius: 20px !important;
  &.bottom {
    align-items: flex-end !important;
  }
  --height: auto !important;
  @media only screen and (max-width: 768px) {
    --width: 95% !important;
  }
  .ion-page {
    position: relative !important;

    display: block !important;

    contain: content !important;

    .inner-content {
      max-height: 80vh !important;

      overflow: auto;

      padding: 10px !important;
    }
  }
}

ion-modal.datetimeModal {
  // --min-height: 25vh !important;
  // --max-height: 80vh !important;
  // --height: auto !important;
  // @media only screen and (max-width: 2560px) {
  //   --height: 25vh !important;
  // }
  // @media only screen and (max-width: 1440px) {
  //   --height: 39vh !important;
  // }
  // @media only screen and (max-width: 1024px) {
  //   --height: 60vh !important;
  // }
  // @media only screen and (max-width: 768px) {
  //   --height: 78vh !important;
  //   --width: 95% !important;
  // }
  // @media only screen and (max-width: 425px) {
  //   --height: 80vh !important;
  //   --width: 95% !important;
  // }
  @media only screen and (min-width: 768px) {
    --height: 66vh !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 80vh !important;
    --width: 95% !important;
  }
}

.confirmation-dialog-box{
  border-radius: 20px !important;
  &.bottom {
    align-items: flex-end !important;
  }
  --height: 100% !important;
  --width: 100% !important;
}

ion-modal.invoice-duedate {
  color: #86888f !important;
  @media only screen and (min-width: 768px) {
    --height: 500px !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 80vh !important;
    --width: 95% !important;
  }
}

ion-modal.account-balance-adjustment {
  @media only screen and (min-width: 768px) {
    --height: 394px !important;
    --width: 500px !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 428px !important;
    --width: 95% !important;
  }
}

ion-modal.report-filter-modal {
  @media only screen and (min-width: 768px) {
    --height: 350px !important;
    --width: 550px !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 428px !important;
    --width: 95% !important;
  }
}

ion-modal.add-email-cycle-options {
  @media only screen and (min-width: 768px) {
    --height: 224px !important;
    --width: 500px !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 250px !important;
    --width: 95% !important;
  }
}

ion-modal.new-cycle-email {
  @media only screen and (min-width: 768px) {
    --height: 90% !important;
    --width: 900px !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 400px !important;
    --width: 95% !important;
  }
  
  ::-webkit-scrollbar {
    width: 6px; background: #ffffff; border-left: #0070BA;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #3a86b8; border-radius: 1ex;
  }
}

ion-modal.view-cycle-email {
  @media only screen and (min-width: 768px) {
    --height: 510px !important;
    --width: 650px !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 400px !important;
    --width: 95% !important;
  }
}

ion-modal.select-template-modal {
  @media only screen and (min-width: 768px) {
    --height: 585px !important;
    --width: 80% !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 400px !important;
    --width: 95% !important;
  }
}

ion-modal.add-account-modal {
  @media only screen and (min-width: 768px) {
    --height: 585px !important;
    --width: 80% !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 400px !important;
    --width: 95% !important;
  }
}

ion-modal.invoice-monthselection {
  --height: 400px !important;
  --width: 300px !important;
  color: #86888f !important;
  &::part(content) {
    border: 1px solid #428cff;
  }
}

ion-modal.report-filter-datepicker {
  color: #86888f !important;
  &::part(content) {
    border: 1px solid #428cff;
    background-color: #ffffff;
  }
  ion-datetime.datetime-email-cycle {
    // background-color: #0070ba !important;
    color: #86888f !important;
    background-color: #ffffff !important;
  }
  @media only screen and (min-width: 768px) {
    --height: 510px !important;
  }
  @media only screen and (max-width: 767px) {
    --height: 80vh !important;
    --width: 95% !important;
  }
}

ion-modal.user-details {
  ion-header {
    --background: white;
  }
  ion-content {
    --background: white;
  }
  @media only screen and (min-width: 768px) {
    &::part(content) {
      border-radius: 0.75rem;
    }
  }
  @media only screen and (max-width: 767px) {
    &::part(content) {
      border-radius: 0px;
    }
  }
}

ion-modal.account-settings {
  ion-header {
    --background: white;
  }
  ion-content {
    --background: white;
  }
  @media only screen and (min-width: 768px) {
    &::part(content) {
      border-radius: 0.75rem;
      width: 730px;
    }
  }
  @media only screen and (max-width: 767px) {
    &::part(content) {
      border-radius: 0px;
    }
  }
}

ion-modal.create-list {
  ion-header {
    --background: white;
  }
  ion-content {
    --background: white;
  }
  @media only screen and (min-width: 768px) {
    &::part(content) {
      border-radius: 0.75rem;
      height: 390px;
    }
  }
  @media only screen and (max-width: 767px) {
    &::part(content) {
      border-radius: 0px;
    }
  }
}

ion-modal.invoice-adjustment {
  ion-header {
    --background: white;
  }
  ion-content {
    --background: white;
  }
  @media only screen and (min-width: 768px) {
    &::part(content) {
      border-radius: 0.75rem;
      height: 260px;
      width: 500px;
      // border: 1px solid #86888f;
      box-shadow: 1px 1px 20px 1px gray;
    }
  }
  @media only screen and (max-width: 767px) {
    &::part(content) {
      border-radius: 0px;
    }
  }
}

ion-toolbar {
  --background: theme("colors.pageBg");
  --border-color: none;
  @media (prefers-color-scheme: dark) {
    --background: theme("colors.pageBg");
    --border-color: none;
  }
  h2 {
    margin: 0 !important;
  }
}

ion-content {
  --background: theme("colors.pageBg");
  @media (prefers-color-scheme: dark) {
    --background: theme("colors.pageBg");
  }
}
ion-menu {
  width: 280px !important;
}

ion-list.sc-ion-select-popover-md {
  background-color: #ffff !important;
}
ion-item {
  background-color: #ffff !important;
}
ion-item.sc-ion-select-popover-md {
  --background: #ffff !important;
}
.item-radio-checked.sc-ion-select-popover-md {
  --color: black !important;
  --background-focused: var(--ion-color-primary, #3880ff) !important;
}

@media (prefers-color-scheme: dark) {
  .md body {
    --ion-text-color: black !important;
  }
}
ion-list.md.list-md.hydrated {
  background-color: white !important;
}
:host(.item-interactive) {
  --background: #ffff !important;
}

.ion-select {
  --background: #ffff !important;
}

ion-item::part(native) {
  //WRITE ALL YOUR CSS RULES HERE
  background-color: white !important;
  /** and lots more **/
}

.iti__country-list {
  color: #0f172a !important;
}

//Full Calender Styling Object

.fc {
  .fc-col-header-cell-cushion {
    color: #0f172a !important;
  }
  .fc-daygrid-day-number {
    color: #0f172a !important;
  }
  .fc-toolbar-title {
    color: gray;
    font-weight: bolder;
  }
  .fc-col-header-cell-cushion {
    /* needs to be same precedence */
    padding-top: 5px !important; /* an override! */
    padding-bottom: 5px !important; /* an override! */
  }
  button {
    background-color: #0070ba !important;
    outline: none !important;
  }
  .fc-day-today {
    background: #e5e5e5 !important;
    border: none !important;
  }
}
#ph_number {
  padding-right: 180px !important;
}

ion-datetime.datetime-email-cycle {
  // background-color: #0070ba !important;
  color: #86888f !important;
}

ion-modal.timeOnlyModal {
  --height: 250px !important;
  --width: 250px !important;
  color: #86888f !important;
  &::part(content) {
    border: 1px solid #428cff;
  }
}

ion-popover.email-info::part(content) {
  margin-top: 4px;
}

ion-alert.delete-template-alert {
  .alert-wrapper {
    background-color: #ffffff;
    .alert-head {
      h2 {
        color: #4b5563;
      }
    }
    .md button.alert-button.alert-button-cancel {
      --background: #ffffff;
      --background-hover: #ffffff;
      --background-activated: #ffffff;
      --background-focused: #ffffff;
    }
  }
}

@media (prefers-color-scheme: dark) {
  :root {
      .segment-button-checked{
          color: white !important;
      }
  }
}

/* Fallback for older browsers or manual mode */
body.dark {
  .segment-button-checked{
      color: black !important;
  }
}